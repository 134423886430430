import ContactComponent from '../components/ContactComponent'

const ContactPage = () => {
    return (
        <div id="hero3">
            <ContactComponent />
        </div>
    )
}

export default ContactPage
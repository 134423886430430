import logo from '../images/bayer-logo.png'
import Navbar from './Navbar'

const Header = () => {
    return (
        <>
            <header id="header">
                <img id="logo" src={logo} alt="Bayer Roofing's logo" />
                <Navbar/>
            </header>
            <div id="strip">
                <i className="bi bi-envelope-at-fill"></i>
                <a className="m-2" href="mailto:bayerroofingpros@gmail.com">bayerroofingpros@gmail.com</a>
                
                <i className="bi bi-telephone-fill"></i>
                <a className="m-2" href="tel:(262) 225-7001">(262) 225-7001</a>
            </div>
        </>
    )
}

export default Header
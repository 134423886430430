const About = () => {
    return (
        <div id="hero2">
            <section id="about-us">
                <h2>About Us</h2>
                <p>
                    Welcome to Bayer Roofing LLC, your trusted roofing and siding experts!
                    <br />
                    <br />
                    At Bayer Roofing LLC, we pride ourselves on providing high-quality roofing and siding solutions to homeowners and businesses in Southeast Wisconsin and the surrounding areas. With over 20 years of industry experience, we have built a strong reputation for delivering exceptional craftsmanship and top-notch customer service.
                    <br />
                    <br />
                    Our team of skilled professionals is dedicated to ensuring the satisfaction of every client. Whether you need a roof repair, a complete roof replacement, or new siding installation, we have the knowledge and expertise to handle projects of all sizes. We stay up-to-date with the latest industry techniques and materials to deliver durable and long-lasting results.
                    <br />
                    <br />
                    Why choose Bayer Roofing LLC for your roofing and siding needs?
                </p>
                <ul>
                    <li><strong>Expertise</strong>: Our team consists of highly trained and experienced professionals who understand the intricacies of roofing and siding systems. We apply our knowledge to every project, ensuring superior quality and lasting performance.</li>
                    <li><strong>Quality Materials</strong>: We believe in using only the highest quality materials from trusted suppliers. This commitment allows us to provide our clients with roofing and siding solutions that are not only visually appealing but also built to withstand the elements and protect their properties.</li>
                    <li><strong>Attention to Detail</strong>: We pay meticulous attention to every detail of the project, from the initial consultation to the final installation. We take the time to understand our clients' specific needs and preferences, ensuring that the end result exceeds their expectations.</li>
                    <li><strong>Customer Satisfaction</strong>: Customer satisfaction is at the heart of our business. We prioritize open communication, transparency, and timely project completion. We work closely with our clients throughout the process, keeping them informed and involved, so they feel confident in the work being done.</li>
                    <li><strong>Competitive Pricing</strong>: We understand the importance of value for our clients. We offer competitive pricing without compromising on the quality of our work. Our goal is to provide cost-effective solutions that meet your budget requirements.</li>
                </ul>
                <p>
                    Whether you need a roof repair, siding replacement, or a complete exterior transformation, Bayer Roofing LLC is here to deliver exceptional results. Contact us today to schedule a consultation and let us bring your roofing and siding visions to life.
                </p>
            </section>
        </div>
    )
}

export default About

/*

Expertise: Our team consists of highly trained and experienced professionals who understand the intricacies of roofing and siding systems. We apply our knowledge to every project, ensuring superior quality and lasting performance.

Quality Materials: We believe in using only the highest quality materials from trusted suppliers. This commitment allows us to provide our clients with roofing and siding solutions that are not only visually appealing but also built to withstand the elements and protect their properties.

Attention to Detail: We pay meticulous attention to every detail of the project, from the initial consultation to the final installation. We take the time to understand our clients' specific needs and preferences, ensuring that the end result exceeds their expectations.

Customer Satisfaction: Customer satisfaction is at the heart of our business. We prioritize open communication, transparency, and timely project completion. We work closely with our clients throughout the process, keeping them informed and involved, so they feel confident in the work being done.

Competitive Pricing: We understand the importance of value for our clients. We offer competitive pricing without compromising on the quality of our work. Our goal is to provide cost-effective solutions that meet your budget requirements.

*/
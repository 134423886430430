import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';

import Home from './pages/Home'
import ContactPage from './pages/ContactPage'
import About from './pages/About'

import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <BrowserRouter basename="">
      <main id="main-content">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/About" element={<About />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

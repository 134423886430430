const Home = () => {
    return (
        <div id="hero">
            <h1>Proudly Servicing South-Eastern Wisconsin</h1>

            <ul id='services' className="mx-auto">
                <li>Roofing Tear Off</li>
                <li>Install</li>
                <li>Repair</li>
                <li>Inspection</li>
                <li>Gutters</li>
                <li>EPDM Rubber Work</li>
                <li>Siding</li>
            </ul>
        </div>
    )
}

export default Home
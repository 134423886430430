import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="mx-auto">
            <Link className="navbar-link m-2" to="/">Home</Link>
            <Link className="navbar-link m-2" to="/Contact">Contact</Link>
            <Link className="navbar-link m-2" to="/About">About</Link>
        </nav>
    )
}

export default Navbar